<template>
  <v-container id="SignInPage" :fluid="true">
    <v-layout align-center justify-center row fill-height>
      <v-flex xs6 class="left-column text-xs-right d-flex" fill-height>
        <h1>
          Gestão de Formação <br />
          (LMS)
        </h1>
      </v-flex>
      <v-flex xs6 align-self-center>
        <div class="text-xs-center mb-3">
          <figure class="my-2">
            <img src="/assets/imgs/logo.png" width="250" alt="Form@" />
          </figure>
        </div>
        <v-form v-if="recoverPassword" class="mx-auto" @submit.prevent="onSubmitRecover">
          <p class="size--s18 text-xs-center">Recuperar Senha</p>
          <v-text-field v-model="recoverPassword.Password" label="Senha" required type="password" autocomplete="false" :error="$v.recoverPassword.Password.$error" @blur="$v.recoverPassword.Password.$touch()"></v-text-field>
          <v-text-field label="Repetir Senha" required type="password" v-model="recoverPassword.RepeatedPassword" autocomplete="false"></v-text-field>
          <div class="form-group text-center">
            <v-btn color="accent" type="submit" :loading="loading">Entrar</v-btn>
          </div>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import * as types from "@/store/types";
// import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import router from "@/router/router";
import authService from "@/services/api/authService";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";

export default {
  data () {
    return {
      RecoverPassword: false,
      loading: false,
      isRecover: false,
      recoverPassword: {
        Password: "",
        RepeatedPassword: "",
        Token: "",
      },
    };
  },
  validations: {
    recoverPassword: {
      Password: {
        required,
        minLength: minLength(4),
      },
    },
  },
  methods: {
    // ...mapActions({ login: types.RecoverPassword }),
    onSubmitRecover () {
      this.recoverPassword.Token = this.$route.query.token;
      if (
        this.recoverPassword.Password == this.recoverPassword.RepeatedPassword
      ) {
        authService
          .post("/updatepassword", this.recoverPassword)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Informação",
              "Senha actualizada"
            );
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
            this.loading = false;
          })
          .finally(() => {
            router.push("auth/signin");
          });
      } else {
        notificationServiceBus.showError("Senhas não coincidem");
      }
    },
  },
};
</script>

<style scoped lang="scss">
#SignInPage {
  height: 100vh;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
  background: #fff;

  .left-column {
    align-items: center;
    background-image: url("/assets/imgs/.jpg");
    background-size: cover;
  }
}

form {
  max-width: 350px;
}

h1 {
  color: #fff;
  padding-right: 90px;
  font-size: 49px;
}
</style>
