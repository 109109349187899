<template>
  <v-container id="SignInPage" :fluid="true">
    <v-layout align-center justify-center row fill-height>
      <v-flex xs6 class="left-column text-xs-right d-flex" fill-height>
        <h1>
          Gestão de Formação <br />
          (LMS)
        </h1>
      </v-flex>
      <v-flex xs6 align-self-center>
        <div class="text-xs-center mb-3">
          <figure class="my-2">
            <img src="/assets/imgs/logo.png" width="250" alt="APDP - Formação" />
          </figure>
        </div>
        <transition name="fade" mode="out-in">
          <v-form v-if="!isRecover" class="mx-auto" @submit.prevent="onSubmitLogin">
            <p class="size--s18 text-xs-center">Entrar na sua conta</p>
            <v-text-field v-model="user.Username" label="Código" required @blur="$v.user.Username.$touch()"></v-text-field>
            <v-text-field v-model="user.Password" label="Senha" required type="password" autocomplete="false" :error="$v.user.Password.$error" @blur="$v.user.Password.$touch()"></v-text-field>
            <div class="form-group">
              <a href="#" class="pull-right" @click.prevent="isRecover = !isRecover">Recuperar password ?</a>
              <label class="container-cb">
                Relembrar
                <input v-model="user.RememberLogin" type="checkbox" name="Confirm" class="required" />
                <span class="checkmark" />
              </label>
            </div>
            <div class="form-group text-center">
              <v-btn color="accent" type="submit" :loading="loading" :disabled="$v.user.$invalid">Entrar</v-btn>
            </div>
          </v-form>
          <v-form v-else key="2" class="mx-auto" @submit.prevent="onSubmitRecover">
            <p class="size--s18">Recupere a sua password</p>
            <div class="form-group">
              <v-text-field v-model="user.Email" label="Email" required @blur="$v.user.Username.$touch()"></v-text-field>
              <a href="#" @click.prevent="isRecover = !isRecover">Cancelar</a>
            </div>
            <div class="text-center">
              <v-btn type="submit" color="accent">Recuperar</v-btn>
            </div>
          </v-form>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import * as types from "@/store/types";
import { mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import router from "@/router/router";
import authService from "@/services/api/authService";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";

export default {
  data () {
    return {
      loading: false,
      isRecover: false,
      user: {
        Username: "",
        Password: "",
        RememberLogin: false,
        Email: "",
      },
    };
  },
  validations: {
    user: {
      Username: {
        required,
        minLength: minLength(2),
      },
      Password: {
        required,
        minLength: minLength(4),
      },
    },
  },
  methods: {
    ...mapActions({ login: types.USER_LOGIN }),
    onSubmitLogin () {
      if (!this.loading) {
        this.loading = true;
        authService
          .post("/login", this.user)
          .then((response) => {
            const data = response.data;
            this.login(data);
            notificationServiceBus.showSuccessMessage(
              "Informação",
              "Login efetuado com sucesso"
            );
            router.replace(data.Redirect);
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
            this.loading = false;
          });

      }
    },
    onSubmitRecover () {
      authService
        .post("/recover", this.user)
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Informação",
            "Verifique o seu email para recuperar a password"
          );
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
          this.loading = false;
        })
        .finally(() => {
          this.user.Email = "";
          this.user.Username = "";
          this.user.Password = "";
        });
      this.isRecover = false;
    },
  },
};
</script>

<style scoped lang="scss">
#SignInPage {
  height: 100vh;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
  background: #fff;

  .left-column {
    align-items: center;
    background-image: url("/assets/imgs/login-left-side-bg.png");
    background-size: cover;
  }
}

form {
  max-width: 350px;
}

h1 {
  color: #fff;
  padding-right: 90px;
  font-size: 49px;
}
</style>
